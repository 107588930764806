import React from "react"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageWrapper, TextWrapper, HeadingWrapper } from "../components/contentwrappers"
import ActionButton from "../components/actionbutton"

const NotFoundPage = props => (
  <>
    <BackgroundImage
      fluid={props.data.royalCourtsImage.childImageSharp.fluid}
      alt="The Royal Courts of Justice"
      style={{ height: "100vh", width: "100vw", position: "fixed", top: "0", left: "0", zIndex: "-9" }}
    >
    </BackgroundImage>

    <Layout>
      <SEO title="Not Found" />
      <PageWrapper>
        <HeadingWrapper>
          <h1>Page <strong>Not Found</strong></h1>
        </HeadingWrapper>
        <TextWrapper>
          <h2><strong>Sorry</strong>...</h2>
          <p>The page you were looking for doesn't seem to exist.</p>
          <div style={{ textAlign: "center" }}>
          <ActionButton toRef="/">Back to the <strong>home</strong> page</ActionButton>
          </div>
        </TextWrapper>





      </PageWrapper>
    </Layout>
  </>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    royalCourtsImage: file(relativePath: { eq: "royal-courts-of-justice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5031, quality:60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`